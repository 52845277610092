@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.searchHistoryWrapper {
  background: colors.$background;
  height: 100vh;
  width: 100vw;
  left: 0;
  position: absolute;
  padding: 0px 10px;
  margin-top: 25px;
  margin-left: -10px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
  transform-origin: top center;

  @media screen and (min-width: vars.$small) {
    margin-left: -15px;
    padding: 0px 15px;
  }

  @media screen and (min-width: vars.$large) {
    width: 100%;
    margin-left: 0;
    padding: 0px;
  }

  @media screen and (min-width: vars.$extra-large) {
    background: none;
    height: initial;
    z-index: 1;
  }
}

.visible {
  opacity: 1;
  visibility: visible;
}

.closeSearchHistory {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  color: colors.$primary-color;

  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }
}

.historyItemBox {
  background-color: colors.$white;
  border-radius: 4px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  left: 0;
  margin-top: -5px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  right: 0;
  width: 100%;
}

.query {
  color: colors.$default-text;
  margin-right: 4px;
}

.historyItemType {
  position: relative;

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  &:first-child {
    .link {
      &:before {
        content: '';
        position: absolute;
        top: -9px;
        width: 0;
        height: 0;
        left: 15px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid colors.$white;

        @media screen and (min-width: vars.$extra-large) {
          left: 15.5%;
        }
      }

      &:focus,
      &:hover {
        background-color: colors.$primary-color;
        color: colors.$white;

        &:before {
          border-bottom: 10px solid colors.$primary-color;
        }

        ~ span {
          color: colors.$white;
        }

        span {
          color: colors.$white;
        }
      }
    }
  }

  &:hover,
  &:focus {
    background-color: colors.$primary-color;
    color: colors.$white;

    a {
      color: colors.$white;
    }

    span {
      color: colors.$white;
    }
  }
}

.in {
  color: colors.$primary-color;
}

.link {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 100%;
  font-family: 'TT Prosto Sans Condensed', sans-serif;
  padding: 15px;
  overflow: hidden;
  text-decoration: none;
  width: 100%;

  @media screen and (min-width: vars.$medium) {
    padding: 10px 15px;
  }
}

.historyItemName {
  font-weight: bold;
  font-stretch: condensed;
  line-height: 1.125rem;
  position: absolute;
  right: 15px;
  top: 15px;

  @media screen and (min-width: vars.$medium) {
    top: 10px;
  }
}

.selected {
  background-color: colors.$primary-color;
  color: colors.$white;

  a,
  span {
    color: colors.$white;
  }
}

.historyItemType.selected:first-child a:before {
  border-bottom: 10px solid colors.$primary-color;
}
